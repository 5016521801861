import React from 'react'
import Card from '@material-ui/core/Card'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'

function Badge (props) {
  return (
    <Grid item xs={12} sm={6}>
      <Card cols={1}>
        <CardActionArea onClick={() => window.open(props.href)}>
          <CardMedia
            component='img'
            height='140'
            image={props.img}
          />
          <CardContent>
            <Typography gutterBottom variant='h5' component='h2'>
              {props.children}
            </Typography>
            <Typography component='p'>
              {props.detail}
            </Typography>
          </CardContent>
        </CardActionArea>
        <CardActions>
          <Button variant='contained' color='primary' onClick={() => window.open(props.href + props.login)}>
            {props.newAccount ? 'Login' : 'Login/Create Account'}
          </Button>
          {props.newAccount && (
            <Button variant='contained' color='secondary' onClick={() => window.open(props.href + props.newAccount)}>
              {'Create Account'}
            </Button>
          )}
        </CardActions>
      </Card>
    </Grid>
  )
}

export default Badge
