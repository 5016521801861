import React from 'react'
import ReactDOM from 'react-dom'
import Appbar from './appbar.js'
import Grid from '@material-ui/core/Grid'
import Badge from './badges.js'
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles'
import awanaLogo from '../res/awana-clubs-logo-bw.jpg'
import presentationLogo from '../res/presentation-light.svg'

const theme = createMuiTheme({
  typography: {
    useNextVariants: true
  },
  palette: {
    primary: {
      main: '#6a1b9a'
    },
    secondary: {
      main: '#ce93d8'
    }
  }
})

function App (props) {
  return (
    <MuiThemeProvider theme={theme}>
      <Appbar />
      <Grid container alignContent='center' alignItems='center' spacing={24}>
        <Badge img={awanaLogo} href={'https://awana.hereforjesus.com/'} login={'login.html'} newAccount={'new.html'} detail={'Student Record Keeping: Allows church to check-in students and manage the student\'s point accumulation.'}>{'Awana Console'}</Badge>
        <Badge img={presentationLogo} href={'https://slides.hereforjesus.com/'} login={'login.html'} detail={'Worship Slides Over URL: Enables church presentations to be displayed over wifi to laymen devices as well as to multiple screens.'}>{'Church Slides'}</Badge>
      </Grid>
    </MuiThemeProvider>
  )
}

let renderer = document.createElement('div')
document.body.appendChild(renderer)

ReactDOM.render(<App />, renderer)
