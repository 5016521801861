import React from 'react'
import { withStyles } from '@material-ui/styles'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'

const style = {
  root: {
    marginBottom: 20
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20
  }
}

function Appbar (props) {
  const { classes } = props

  return (
    <AppBar position='sticky' className={classes.root}>
      <Toolbar>
        <Typography noWrap variant='h6'>
          {'Here For Jesus Apps'}
        </Typography>
      </Toolbar>
    </AppBar>
  )
}

export default withStyles(style)(Appbar)
